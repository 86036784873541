@import url("https://fonts.googleapis.com/css?family=Montserrat:regular,700&display=swap");

body {
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #202020;
}

h1 {
    font-size: 48px;
    font-weight: 400;
    line-height: 59px;
}

.online {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    color: rgba(32, 32, 32, .5);
    display: flex;
    align-items: center;
    gap: 8px;
}

.online:before {
    content: '';
    width: 8px;
    min-width: 8px;
    height: 8px;
    background: #36D881;
    border-radius: 50%;
}

.container {
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}

label {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    color: rgba(32, 32, 32, .5);
    display: block;
    margin-bottom: 4px;
}


.form {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.form__field-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.form__field-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.form__input {
    position: relative;
}

.form__input-button {
    position: absolute;
    right: 6px;
    top: 0;
    bottom: 0;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form__buttons {
    display: flex;
    gap: 16px;
}

.form__buttons .button {
    width: 100%;
}

h6 {
    line-height: 1;
}