.button {
	outline: none;
	background: none;
	border: none;
	box-shadow: none;
	border-radius: 0;
	-webkit-appearance: none;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
    vertical-align: middle;
}

.button._create {
	height: 56px;
	background: #4285B4;
	padding: 0 34px;
	border-radius: 56px;
	color: #fff;
	font-size: 20px;
	font-weight: 500;
	line-height: 24px;
	gap: 8px;
}

.button._add-email {
	color: #4285B4;
	font-size: 12px;
	font-weight: 500;
	line-height: 15px;
	gap: 4px;
}

.button._default {
	height: 40px;
	padding: 10px 24px 10px 24px;
	border-radius: 40px;
	background: #4285B4;
	color: #fff;
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
}

.button._default._outline {
	border: 1px solid #4285B4;
	color: #4285B4;
	background: transparent;
}

.button._disable {
	filter: grayscale(100);
	pointer-events: none;
	opacity: .5;
}