.page._home {
    padding-top: 64px;
}

.page._home .page__header {
    margin-bottom: 64px;
}

.page._home .page__header .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.page._home .page__header .page__title {
    margin-right: 16px;
}

.page._home .page__header-actions {
    margin-left: auto;
    display: flex;
    gap: 30px;
    align-items: center;
}

.page._home .page__search {
    margin-bottom: 24px;
}

.page._home .page__search .container {
    display: flex;
    justify-content: flex-end;
}