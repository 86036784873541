.report a {
    color: inherit
}

.report table {
    width: 100%;
    table-layout: fixed;
}

.report td,
.report th {
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
    text-align: left;
}