.modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    opacity: 0;
    display: none;
    visibility: hidden;
    pointer-events: none;
  }
  .modal._active {
    opacity: 1;
    pointer-events: all;
    display: block;
    visibility: visible;
  }
  .modal__title {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 24px;
  }
  .modal__bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    background: #202020;
    opacity: 0.65;
  }
  .modal__wrap {
    position: absolute;
    left: 0;
    top: 0px;
    right: 0;
    bottom: 0px;
    z-index: 200;
    overflow: auto;
    padding: 80px 16px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: justify;
    justify-content: space-between;
    pointer-events: none;
  }
  .modal__wrap:before {
    content: '';
    width: 1px;
    display: block;
  }
  .modal__wrap:after {
    content: '';
    width: 1px;
    display: block;
  }
  .modal__close {
    position: absolute;
    right: 16px;
    top: 16px;
    border: none;
    line-height: 14px;
    z-index: 100;
    transition: .2s;
  }
  .modal__close:hover {
    opacity: 1;
  }
  .modal__container {
    max-width: 430px;
    width: 100%;
    background: #Fff;
    position: relative;
    border-radius: 12px;
    margin-left: auto;
    margin-right: auto;
    padding: 24px 30px 32px;
    pointer-events: all;
  }

  .modal._report .modal__container {
    max-width: 600px;
  }

  .modal._report + .modal._report {
    z-index: 10;
  }