.input {
    font-family: 'Montserrat';
    outline: none;
	background: none;
	border: none;
	box-shadow: none;
	border-radius: 0;
	-webkit-appearance: none;
	resize: none;
    color: #202020;
}

.input._default {
    width: 100%;
    height: 36px;
    padding: 8px 16px 8px 16px;
    border-radius: 4px;
    background: #2020200D;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
}

.input._with-button {
    padding-right: 36px;
}

.input._search {
    height: 40px;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
}