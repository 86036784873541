.table._parser .table__row {
    display: flex;
    gap: 16px;
    border-bottom: 1px solid rgba(66, 133, 180, .15);
}

.table._parser .table__row:last-child {
    border: none;
}

.table._parser .table__row._header {
    color: #4285B4;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    align-items: center;
}

.table._parser .table__td {
    padding: 24px 0;
}

.share-email {
    display: flex;
    gap: 8px;
    align-items: center;
}

.table._parser .table__td._name {
    width: 14%;
}

.table._parser .table__td._keywords {
    width: 15%;
}

.table._parser .table__td._report {
    width: 8%;
}

.table._parser .table__td._last-change {
    width: 11%;
}

.table._parser .table__td._last-scan {
    width: 11%;
}

.table._parser .table__td._date-create {
    width: 11%;

}

.table._parser .table__td._emails {
    width: 22%;
}

.table._parser .table__td._edit {
    width: 4%;
}

.table._parser .table__td._delete {
    width: 2%;
}

.table._parser .table__td._start {
    width: 2%;
}

.table._parser .table__more {
    padding-top: 40px;
}

.tooltip {
    position: absolute;
    left: -70px;
    top: -70px;
    padding: 6px 10px;
    display: flex;
    gap: 8px;
    width: 170px;
    justify-content: center;
    border: 1px solid #4285B4;
    border-radius: 24px;
    background: #fff;
}