.select {
    font-family: 'Montserrat';
    outline: none;
	background: none;
	border: none;
	box-shadow: none;
	border-radius: 0;
	-webkit-appearance: none;
	resize: none;
    color: #202020;
}

.select._default {
    width: 100%;
    height: 36px;
    padding: 8px 36px 8px 16px;
    border-radius: 4px;
    background: #2020200D;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
}

.form__select {
    position: relative;
}

.form__select-icon {
    position: absolute;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 6px;
    top: 6px;
    pointer-events: none;
}